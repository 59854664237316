import React, { Component } from 'react';

// Import component
import NavHeader from '../components/NavHeader.jsx';
import Footer from '../components/Footer.jsx';

// Import custom style
import './HskDates.css'

// Meta tags and Title
import {Helmet} from "react-helmet";

export default class HskDates extends Component {
    render() {
        return (
            <div>
                <Helmet>
                    <title>HSK test dates</title>
                    <meta name="description" content="HSK test dates composed of the examination date, the registration deadline for both Paper based test and Internet based test, as well as the results publication dates for (Paper/Internet)" />
                </Helmet>
                <NavHeader />
                <div className="container">
                    <h1>HSK test dates</h1>
                    <div className="table-responsive">
                    
                                <b>--- 2021 ---</b>
                                <table className="table table-striped">
                    <thead>
                                <tr>
                                    <th scope="col">Examination Date</th>
                                    <th scope="col">Registration Deadline</th>

                                </tr>
                            </thead>
                            <tbody>
                            <tr>
                                    <td>Sept 25 (Sat) 2021</td>
                                    <td>Sept 11</td>
                                </tr>
                                <tr>
                                    <td>Oct 30 (Sat) 2021</td>
                                    <td>Oct 16</td>
                                </tr>
                                <tr>
                                    <td>Dec 11 (Sat) 2021</td>
                                    <td>Nov 25</td>
                                </tr>
                                </tbody>
                    <br /><br /><br />
                                <b>--- 2020 ---</b>
                        
                       
                            <thead>
                                <tr>
                                    <th scope="col">Examination Date</th>
                                    <th scope="col">Registration Deadline (Paper/Internet)</th>
                                    <th scope="col">Results Publication (Paper/Internet)</th>

                                </tr>
                            </thead>
                            <tbody>
                            <tr>
                                    <td>Jan 11 (Sat) 2020</td>
                                    <td>Dec 15 / Jan 1</td>
                                    <td>Feb 18 / Feb 3</td>
                                </tr>
                                <tr>
                                    <td>Feb 9 (Sun) 2020</td>
                                    <td>Jan 13 / Feb 30</td>
                                    <td>Mar 9 / Feb 24</td>
                                </tr>
                                <tr>
                                    <td>Mar 7 (Sun) 2020</td>
                                    <td>- / Feb 26</td>
                                    <td>Mar 23</td>
                                </tr>
                                <tr>
                                    <td>Mar 21 (Sat) 2020</td>
                                    <td>Feb 23 / Mar 11</td>
                                    <td>Apr 21 / Apr 6</td>
                                </tr>
                                <tr>
                                    <td>Apr 11 (Sat) 2020</td>
                                    <td>Mar 15 / Apr 1</td>
                                    <td>May 11 / Apr 27</td>
                                </tr>
                                <tr>
                                    <td>May 9 (Sat) 2020</td>
                                    <td>Apr 12 / Apr 29</td>
                                    <td>June 9 / May 25</td>
                                </tr>
                                <tr>
                                    <td>June 14 (Sun) 2020</td>
                                    <td>May 18 / June 4</td>
                                    <td>July 14 / June 29</td>
                                </tr>
                                <tr>
                                    <td>July 12 (Sun) 2020</td>
                                    <td>June 15 / July 2</td>
                                    <td>Aug 12 / July 27</td>
                                </tr>
                                <tr>
                                    <td>Aug 23 (Sun) 2020</td>
                                    <td>July 27 / Aug 13</td>
                                    <td>Sept 23 / Sept 7</td>
                                </tr>
                                <tr>
                                    <td>Sept 19 (Sat) 2020</td>
                                    <td>Aug 23 / Sept 9</td>
                                    <td>Oct 26 / Oct 12</td>
                                </tr>
                                <tr>
                                    <td>Oct 17 (Sat) 2020</td>
                                    <td>Sept 20 / Oct 7</td>
                                    <td>Nov 17 / Nov 2</td>
                                </tr>
                                <tr>
                                    <td>Nov 7 (Sat) 2020</td>
                                    <td>- /	Oct 28</td>
                                    <td>Nov 23</td>
                                </tr>
                                <tr>
                                    <td>Nov 21 (Sat) 2020</td>
                                    <td>Oct 25 / Nov 11</td>
                                    <td>Dec 21 / Dec 7</td>
                                </tr>
                                <tr>
                                    <td>Dec 6 (Sun) 2020</td>
                                    <td>Nov 9 / Nov 26</td>
                                    <td>Jan 6 / Dec 21</td>
                                </tr>
                                <br />
                                <b>--- 2019 ---</b>
                                <tr>
                                    <th scope="col">Examination Date</th>
                                    <th scope="col">Registration Deadline (Paper/Internet)</th>
                                    <th scope="col">Results Publication (Paper/Internet)</th>

                                </tr>
                                <tr>
                                    <td>Jan 12 (Sat) 2019</td>
                                    <td>Dec 16 / Jan 2</td>
                                    <td>Feb 19 / Jan 28</td>
                                </tr>
                                <tr>
                                    <td>Feb 24 (Sun) 2019</td>
                                    <td>Jan 28 / Feb 14</td>
                                    <td>Mar 24 / Mar 11</td>
                                </tr>
                                <tr>
                                    <td>Mar 10 (Sun) 2019</td>
                                    <td>- / Feb 28</td>
                                    <td>Mar 25</td>
                                </tr>
                                <tr>
                                    <td>Mar 23 (Sat) 2019</td>
                                    <td>Feb 24 / Mar 13</td>
                                    <td>Apr 23 / Apr 8</td>
                                </tr>
                                <tr>
                                    <td>Apr 14 (Sun) 2019</td>
                                    <td>Mar 18 / Apr 4</td>
                                    <td>May 14 / Apr 29</td>
                                </tr>
                                <tr>
                                    <td>May 26 (Sun) 2019</td>
                                    <td>- / May 16</td>
                                    <td>June 10</td>
                                </tr>
                                <tr>
                                    <td>June 16 (Sun) 2019</td>
                                    <td>May 20 / June 6</td>
                                    <td>July 16 / July 1</td>
                                </tr>
                                <tr>
                                    <td>May 11 (Sat) 2019</td>
                                    <td>Apr 14 / May 1</td>
                                    <td>June 11 / May 27</td>
                                </tr>
                                <tr>
                                    <td>June 29 (Sat) 2019</td>
                                    <td>- / June 19</td>
                                    <td>July 15</td>
                                </tr>
                                <tr>
                                    <td>July 13 (Sat) 2019</td>
                                    <td>June 16 / July 3</td>
                                    <td>Aug 13 / July 29</td>
                                </tr>
                                <tr>
                                    <td>July 21 (Sun) 2019</td>
                                    <td>- / July 11</td>
                                    <td>Aug 5</td>
                                </tr>
                                <tr>
                                    <td>Aug 17 (Sat) 2019</td>
                                    <td>July 21 / Aug 7</td>
                                    <td>Sept 17 / Sept 2</td>
                                </tr>
                                <tr>
                                    <td>Sept 22 (Sun) 2019</td>
                                    <td>Aug 26 / Sept 12</td>
                                    <td>Oct 22 / Oct 14</td>
                                </tr>
                                <tr>
                                    <td>Oct 13 (Sun) 2019</td>
                                    <td>Sept 16 / Oct 3</td>
                                    <td>Nov 13 / Oct 28</td>
                                </tr>
                                <tr>
                                    <td>Oct 26 (Sat) 2019</td>
                                    <td>- /	Oct 16</td>
                                    <td>Nov 11</td>
                                </tr>
                                <tr>
                                    <td>Nov 16 (Sat) 2019</td>
                                    <td>Oct 20 / Nov 6</td>
                                    <td>Dec 16 / Dec 2</td>
                                </tr>
                                <tr>
                                    <td>Dec 1 (Sun) 2019</td>
                                    <td>Nov 4 / Nov 21</td>
                                    <td>Jan 1 / Dec 16</td>
                                </tr>
                                <br />
                            </tbody>
                        </table>
                    </div>
                </div>

                <Footer />

            </div>
        )
    }
}
