import React, { Component } from 'react';

// Import component
import NavHeader from '../components/NavHeader.jsx';
import Footer from '../components/Footer.jsx';

// Meta tags and Title
import {Helmet} from "react-helmet";

import {
  Link,
} from 'react-router-dom'

// Import custom style
import './Games.css'

// import { Link } from 'react-router-dom';

export default class Games extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // original hanzis object
      hanzis: [],
      hsklevel: 1,
    };

    // Binding to make `this` work in the callback
    this.handleClickHSKlevel = this.handleClickHSKlevel.bind(this);

  }


  handleClickHSKlevel(e) {
    const id = e.currentTarget.getAttribute('data-hsk');
    this.setState({
  
      hsklevel: id,
    });
  }




  render() {
    return (

      <div>
        <Helmet>
          <title>Play Chinese Learning Games and review all HSK words !</title>
          <meta name="description" content="Chinese Learning Games with HSK words, to help review or to get familiar with the words of each HSK level." />
        </Helmet>
        <NavHeader />
        <div className="page-content">
        <div class="btn-group mr-2 " role="group" aria-label="First group">
          <div class="btn-block-games ml-auto"  role="group">
                    <button type="button" class="btn btn-secondary-games btn-level">HSK level</button>
                    <button type="button" onClick={this.handleClickHSKlevel} class={this.state.hsklevel === 1 ? "btn btn-primary btn-1" : "btn btn-secondary-games btn-1"} data-hsk="1">1</button>
                    <button type="button" onClick={this.handleClickHSKlevel} class="btn btn-secondary-games btn-2" data-hsk="2">2</button>
                    <button type="button" onClick={this.handleClickHSKlevel} class="btn btn-secondary-games btn-3" data-hsk="3">3</button>
                    <button type="button" onClick={this.handleClickHSKlevel} class="btn btn-secondary-games btn-4" data-hsk="4">4</button>
                    <button type="button" onClick={this.handleClickHSKlevel} class="btn btn-secondary-games btn-5" data-hsk="5">5</button>
                    <button type="button" onClick={this.handleClickHSKlevel} class="btn btn-secondary-games btn-6" data-hsk="6">6</button>

                    </div>
                  </div>
        <h1>Play Chinese Learning Games and review all HSK words !</h1>
        <div class="container my-5">
          <div class="card-group">
            <div class="card card-game" >
            <Link
                      to={{
                        pathname: "/chinese-learning-games/pinyin-game-HSK-" + this.state.hsklevel,
                        }}
                    >
              <div class="card-body" >
                <h5 class="card-title">Pinyin & Words</h5>
                <p class="card-text">Find the pinyin corresponding to the words and vice versa</p>
                <div class="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
                  
                
                      
                </div>
              </div> 
                    </Link>
                    
                
            </div>

            <div class="card card-game">
            <Link
                      to={{
                        pathname: "/chinese-learning-games/memory-game-HSK-" + this.state.hsklevel,
                        }}
                    >
              <div class="card-body">
                <h5 class="card-title">Memory Game</h5>
                <p class="card-text">Find two identical words that are hidden in the grid</p>
                
                <div class="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
            
                  
                    
                </div>

              </div>
              </Link>
            </div>
          </div>
          </div>
        </div>
        <Footer />

      </div>
    )
  }
}
