import React from 'react'


const SettingOptions = ({ sort_options, set, onChange }) => {

    if (set === false) {
        // Settings options for wordlist (default settings on landing)
        return (
            <form className="px-4 py-3 card-settings">
                <div className="form-group">
                    <p>Sort cards by:</p>
                    <select key={`${Math.floor((Math.random() * 1000))}-min`}
                        className="form-select" defaultValue={sort_options} onChange={onChange}>
                        <option value="pinyin">Pinyin</option>
                        <option value="rand">Random</option>
                    </select>
                </div>
            </form>
        )
    } else {
        // Settings options for characters
        return (
            <form className="px-4 py-3 card-settings">
                <div className="form-group">
                    <p>Sort cards by:</p>
                    <select key={`${Math.floor((Math.random() * 1000))}-min`}
                        className="form-select" defaultValue={sort_options} onChange={onChange}>
                        <option value="freq">Frequency</option>
                        <option value="pinyin">Pinyin</option>
                        <option value="strokes">Strokes count</option>
                        <option value="genstand">General Standard</option>
                        <option value="rand">Random</option>
                    </select>
                </div>
            </form>
        )
    }
}

export default SettingOptions
