import React, { Component } from 'react';

// Import custom style
import './Footer.css'

export default class Footer extends Component {

    render() {
        return (
            <footer className="footer">
                <div className="container">
                    <div className="footer-group-title">
                    <h4>HSK Abc</h4>
                    </div>
                        <a href="/About-us">
                            <div className="links"><strong>About Us</strong>
                        </div></a>
                        

                    <small>© Copyright 2018-2021 HSK Abc.</small>
                    
                </div>
            </footer>
        )
    }
}
    