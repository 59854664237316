import React, { Component } from 'react';


// Import component
import NavHeader from '../components/NavHeader.jsx';
import Footer from '../components/Footer.jsx';

// Import custom style
import './Aboutus.css' 

// Meta tags and Title
import {Helmet} from "react-helmet";

export default class Aboutus extends Component {
    render() {
        return (
            <div>
                <Helmet>
                    <title>About HSK abc, the best HSK online learning experience</title>
                    <meta name="description" content="At HSK abc, we are dedicated to offer you the best HSK online learning experience so that you can quickly speak Mandarin Chinese like a boss !" />
                </Helmet>
                <NavHeader />
                <h1> -------   SUPPORT US BY SHARING OR WEBSITE -------</h1>
                <div className="col-sm support">
                    
                    <p> If you want to support us, simply share the hskabc.com website to your friends, teachers, classmates, and on websites such as reddit, facebook, tweeter, etc. </p>

                    <p> Words list for HSK1 : https://hskabc.com/New-HSK1-vocabulary </p>

                    


                </div>
                <h1>About HSK abc, the best HSK online learning experience</h1>
                <div className="col-sm about">

                    <p> You´re a foreign student or business professional eager to learn Mandarin Chinese ? You’re definitely at the right place ! HSK abc will provide you with the best online tools and resources to be successful. </p>

                    <p> At HSK abc, we have a genuine passion for Asian culture and Chinese language, this is why we want to share it why you ! </p>

                    <p> Having HSK will guide you through the most commonly used Chinese words learning step by step (from level 1 to 9) and speak Mandarin Chinese like a boss ! </p>

                    <p> We have a serious gaming approach about HSK because we are convinced you’ll learn better if you have fun in the process. This is why you’ll find a broad range of <strong><a href="/chinese-learning-games">  chinese learning games </a></strong> to help you out.</p>

                    <p> You’ll also find classical and useful resources to learn  HSK vocabulary such as <strong><a href="/New-HSK1-vocabulary">hsk words lists</a></strong> and <strong><a href="/HSK-1/New-HSK-1-characters">hsk characters list.</a></strong></p>

                    <p> We are an international blend of friends with various skill sets ranging from Linguistics to Computer Science through UX Design and Engineering.</p>

                    <p> We are committed to help you deepen your knowledge about Chinese language, culture and successfully pass HSK levels until you're quickly fluent !</p>

                    <p> We just love growing this community and improving your <strong> HSK online </strong> learning experience together ! Do you have ideas ? feedback ? new games or features proposition ? Please share it with us and let's grow together :)</p>



                </div>
                
                <Footer />

            </div>
        )
    }
}
