import React, { Component } from 'react';
import axios from 'axios'

// Import component
import NavHeader from '../components/NavHeader.jsx';
import Footer from '../components/Footer.jsx';
import GridGameMemory from '../components/GridGameMemory.jsx';
import GetHSKLevel from '../components/GetHSKLevel.jsx';

// Meta tags and Title
import {Helmet} from "react-helmet";

import { Link } from 'react-router-dom';

// Import custom style
import './Games.css'

export default class GameMemory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // original hanzis object
            hanzis: [],
            // Games list
            hanzisGame: [],
            hanziCurrent: ["charac",
                "display",
                false],
            selectedhanzi: [],
            hanzi: "no",
            previousId: -1,
            nbrFound: 0,
            nbrTotal: 0,

            // set last game tile
            lasthanzi: '',
            winningMsg: 'you are playing',
            count: 45,
            shouldHide: false,
            hsklevel: 1,
            title : "",
        };

        // Binding to make `this` work in the callback
        this.handleClickCharacter = this.handleClickCharacter.bind(this);
        this.handleDisplay = this.handleDisplay.bind(this);
        this.stopTimer = this.stopTimer.bind(this);
        this.refreshPage = this.refreshPage.bind(this);

    }


    // 
    componentDidMount() {
        const urltest = window.location.pathname; //this.props.location.search;
        var hskcurrentlevel = 0;
        var currentTitle = "";
        hskcurrentlevel = GetHSKLevel(urltest);
        currentTitle = "Memory game with HSK " + hskcurrentlevel + " words"
        this.setState({
            hsklevel: hskcurrentlevel,
            title: currentTitle,          //wordslist vs characterslist false == wordslist
        });

        // by default on landing on the page without redirection get the HSK1 list
        if (isNaN(hskcurrentlevel)) {
            this.fetchList(1);
        } else {
            this.fetchList(hskcurrentlevel);
        }
        
        // Timer  https://www.youtube.com/watch?v=NAx76xx40jM

        this.myInterval = setInterval(() => {
            if (this.state.count > 0) {
                this.setState(prevState => ({
                    count: prevState.count - 1

                }))
            }
        }, 1000)
    }

    // Fetch data function
    // argument level to call for selected hsklevel
    fetchList(id) {
        axios.defaults.headers.common = {};
        axios.defaults.headers.common.accept = 'application/json';

        // Get a randomized list to a certain number
        axios.get('https://api.hskabc.com/games/wordlist/' + id +'/10')
            .then(res => {
                this.setState({
                    hanzis: res.data,
                })


                // Fill the game list of pinyin and characters
                this.state.hanzis.forEach(han => {
                    this.setState({
                        hanzisGame: this.state.hanzisGame.concat({
                            "character": han.character,
                            "display": "",
                            "found": false
                        },
                            {
                                "character": han.character,
                                "display": "",
                                "found": false
                            })
                    })
                });

                // randomization of pinyin and characters
                // set number to be found
                this.setState({
                    hanzisGame: this.state.hanzisGame.sort(() => 0.5 - Math.random()),
                    nbrTotal: this.state.hanzisGame.length
                });
            });
    }


    componentWillUnmount() {
        clearInterval(this.myInterval)
    }

    stopTimer = () => {
        // this.setState({ timerOn: false });
        clearInterval(this.myInterval);
    };

    handleDisplay(id) {
        const prevId = this.state.previousId;
        const hanziCurrent = this.state.hanzisGame;
        var nbrFoundconst = this.state.nbrFound;

        if (prevId >= 0) {
            if (hanziCurrent[id].display === hanziCurrent[prevId].display && id !== prevId) {
                hanziCurrent[id].found = true;
                hanziCurrent[prevId].found = true;
                nbrFoundconst = this.state.nbrFound + 2;
            } else if (hanziCurrent[prevId].found === false) {
                hanziCurrent[prevId].display = "";
            }
        }
        this.setState({
            hanzisGame: hanziCurrent,
            previousId: id,
            hanzi: id,
            nbrFound: nbrFoundconst
        });

        if (nbrFoundconst === this.state.nbrTotal) {
            // Stop the timer
            this.stopTimer();

            this.setState({
                shouldHide: true
            });
        }

    }

    refreshPage() {
        window.location.reload();
    }


    // Toggle Hanzi to get translation and other info in the side card
    handleClickCharacter(e) {

        const id = e.target.id;
        const hanziCurrent = this.state.hanzisGame;
        hanziCurrent[id].display = hanziCurrent[id].character;

        this.setState({
            hanzisGame: hanziCurrent,
        }, function () {
            this.handleDisplay(id);
        }
        );

    };





    render() {
        return (
            <div>
                <Helmet>
                    <title>{this.state.title}</title>
                    <meta name="description" content="Pair the 2 identical words hidden in the game, will you have enough time to succeed?" />
                </Helmet>
                <NavHeader />
                <div className="page-content">
                    <h1>{this.state.title}</h1>
                    <div class="container my-5">
                        <div className="row">
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <h1> {this.state.count}</h1>
                        </div>

                        <div>
                            <GridGameMemory res={this.state.hanzisGame}
                                onClick={this.handleClickCharacter} />
                        </div>
                        <div>
                            {this.state.shouldHide &&
                                <div className="result-end">

                                    <h4 className="text-muted">Congratulations !!!</h4>
                                    <button type="button" onClick={this.refreshPage} className="btn btn-success">Play again</button>
                                    <Link to="/chinese-learning-games">
                                        <button type="button" className="btn btn-info">Back to Games</button>
                                    </Link>
                                </div>

                            }
                        </div>
                    </div>
                </div>
                <Footer />

            </div>
        )
    }
}

