import React from 'react'

// Custom style
import './GridGames.css'

const GridGames = ({ res, set, onClick }) => {
    // Create grid card element to display
    // Create card by mapping over the hanzis fetched in home
    const hanzi = Object.keys(res).map((h, i) => {
        if (res[h].found === false) {
            
            return (
                <button key={i} id={i} style={{ fontSize: res[h].isPinyin? 'calc(0.7em + 0.7vw)': 'calc(1.5em + 0.7vw)' }} onClick={onClick} className={`col-1 btn hanzi `}>
                    {res[h].display}
                </button>
            )
        }     
        
    });

    return (
        <div>
            {hanzi}
        </div>

    )
};


export default GridGames

