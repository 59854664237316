// Fonction pour voir sur quel hsk level la page se situe en fonction de l'URL

function GetHSKLevel(url) {

    var hskcurrentlevel = 0;

    if (url.includes(1)) {
        hskcurrentlevel = 1;
    } else if (url.includes(2)) {
        hskcurrentlevel = 2;
    } else if (url.includes(3)) {
        hskcurrentlevel = 3;
    } else if (url.includes(4)) {
        hskcurrentlevel = 4;
    } else if (url.includes(5)) {
        hskcurrentlevel = 5;
    } else if (url.includes(6)) {
        hskcurrentlevel = 6;
    }

    return (hskcurrentlevel);
}

export default GetHSKLevel;