import React from 'react';
import axios from 'axios';

// Import custom style
import './PlaySound.css'

function PlaySound(characters) {

    axios.defaults.headers.common = {};
    axios.defaults.headers.common.accept = 'application/json';

    let audio = new Audio("")
    let url = "";

    axios.get('https://api.hskabc.com/audio/' + characters.characters)
        .then(res => {
            url = res.data;
        });

    const start = () => {
        //console.log(characters, url);
        audio = new Audio(url);
        audio.play()
    }

    return (
             <button onClick={start} className="btn btn-light btn-circle-sm m-1"><i className="fa fa-play-circle"></i></button>
    );
}

export default PlaySound;