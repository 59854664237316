import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Import custom style
import './Home.css'

// Import image
import welcomeimg from '../media/img/welcomehh.png';
// import gridimg from '../media/img/gridh1.png';
import logo from '../media/img/hsk-logo-l3-2.png'

// Import component
import NavHeader from '../components/NavHeader.jsx';
import Footer from '../components/Footer.jsx';

// Meta tags and Title
import {Helmet} from "react-helmet";

export default class Home extends Component {


    render() {
        return (
            <div>
                <Helmet>
                    <title>New HSK list of words and characters, New HSK1 wordlist</title>
                    <meta name="description" content="New HSK Vocabulary lists HSK 1 words and characters lists are searchable, sortable, and smoothly displayed on all devices. 新的 HSK1 词汇表, HSK1 nueva lista de palabras, HSK1 danh sách từ mới, daftar kata baru senarai perkataan baru, รายการคำศัพท์ใหม่." />
                    <link rel="canonical" href="https://hskabc.com/New-HSK1-vocabulary" /></Helmet>
                <NavHeader />
                <div className="page-content">

                <title>New HSK list of words and characters, New HSK1 wordlist</title>
                <meta name="description" content="New HSK Vocabulary lists HSK 1 words and characters lists are searchable, sortable, and smoothly displayed on all devices. 新的 HSK1 词汇表, HSK1 nueva lista de palabras, HSK1 danh sách từ mới, daftar kata baru senarai perkataan baru, รายการคำศัพท์ใหม่."/>
                <div className="container home-desktop">
                    <div className="row mt-4">
                        <div className="col-sm">
                            <h4 className="text-muted">Welcome to HSK abc!</h4>
                            <p className="text-muted">You can find lists of words and characters for the HSK test (Hanyu Shuiping Kaoshi) from level 1 to level 9.</p>
                            <p className="text-muted">HSKabc.com is an ad-free web application designed by students to support the Chinese Language Community.</p>
                        </div>
                        <div className="col-sm align-items-center mb-3">
                            <a href="/New-HSK1-vocabulary">
                                <img className="img-fluid" alt="" src={welcomeimg} />
                            </a>

                        </div>
                    </div>

                    <div className="row align-items-center">
                        <div className="col-sm">
                            <h4 className="text-muted">HSK Vocabulary Lists</h4>
                            <p className="text-muted">Our HSK Vocabulary lists are searchable, sortable, and smoothly displayed on all devices. Users can easilly switch in between official list of words into the lists of characters composing the words.</p>
                            <div className="col text-center">
                                <Link to="/New-HSK1-vocabulary">
                                    <button type="button" className="btn btn-secondary">Go to HSK Lists</button>
                                </Link>
                            </div>
                            <p className="text-muted pt-3">HSK abc wishes you good studies in learning Chinese Language.</p>

                        </div>
                        <div className="col-sm align-items-center margin-mobile">
                            <a href="/New-HSK1-vocabulary">
                                <img className="d-block img-fluid" alt="" src={logo}/>
                            </a>
                        </div>
                    </div>

                    </div>
                </div>
                <Footer />
            </div >
        )
    }
}

