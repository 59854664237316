import React, { Component } from 'react';


// Import component
import NavHeader from '../components/NavHeader.jsx';
import Footer from '../components/Footer.jsx';



export default class Faq extends Component {
    render() {
        return (
            <div>
                <NavHeader />
                <div className="page-content">
                <div class="container my-5">
                    <section>
                        <h3 class="font-weight-bold black-text mb-4 pb-2 text-center">HSK Frequently Asked Questions</h3>


                        <div class="row text-center text-md-left">
                            <div class="col-md-6 mb-4">
                                <h5 class="font-weight-normal mb-3">What is HSK?</h5>
                                <p class="text-muted">HSK stands for 'Hanyu Shuiping Kaoshi' (汉语水平考试) meaning Chinese Language Proficiency test. 
                                It is the main Chinese language test for non-native speakers, composed of 9 levels. 
                                HSK is a written test with three parts: Listening, Reading, and Writing.
                                Tests are available in multiple countries on dedicated test centers.
                                HSK Speaking tests are also available.                             
                                
                                </p>
                            </div>

                            <div class="col-md-6 mb-4">
                                <h5 class="font-weight-normal mb-3">How many words/characters per HSK level?</h5>
                                <p class="text-muted">HSK 1 : 500 words - 300 characters<br />
                                HSK 2 : 1272 words (772 new words) - 600 characters<br />
                                HSK 3 : 2245 words (973 new words) - 900 characters<br />
                                HSK 4 : 3245 words (1000 new words) - 1200 characters<br />
                                HSK 5 : 4316 words (1071 new words) - 1500 characters<br />
                                HSK 6 : 5456 words (1140 new words) - 1800 characters<br />
                                HSK 7-9 : 11092 words (5636 new words) - 3000 characters<br /></p>
                            </div>

                            <div class="col-md-6 mb-4">
                                <h5 class="font-weight-normal mb-3">What is HSK offical website?</h5>
                                <p class="text-muted"><a href="http://www.chinesetest.cn">www.chinesetest.cn</a> is the offical website for HSK tests, it is available in several languages, more information about HSK are to be found on this website. </p>
                            </div>

                            <div class="col-md-6 mb-4">
                                <h5 class="font-weight-normal mb-3">How to register to an HSK test session? </h5>
                                <p class="text-muted">Sign in the official website and follow the instructions.</p>
                            </div>

                        </div>
                    </section>
                </div>
                </div>
                <Footer />

            </div>
        )
    }
}
