import React from 'react'

// Custom style
import './GridMaster.css'

const GridMaster = ({ res, set, onClick }) => {
    // Create grid card element to display
    // Create card by mapping over the hanzis fetched in home

    const hanzi = Object.keys(res).map((h, i) => {
        return (
            <button key={i} id={i} onClick={onClick} className={`col-1 btn hanzi ${set === true ? 'word' : ''}`}>
                {res[h].character}
            </button>
        )
    });

    return (
        <div>
            {hanzi}
        </div>

    )
};


export default GridMaster

