 import React, { Component } from 'react';

 // Import custom style
 import './HskGrid.css'

 import {
  BrowserRouter as Router,
  Link,

} from 'react-router-dom'
 
 // Import component
 import NavHeader from '../components/NavHeader.jsx';
 import Footer from '../components/Footer.jsx';
 import GridMaster from '../components/GridMaster.jsx';
 import CardCarousel from '../components/CardCarousel.jsx';
 import SettingOptions from '../components/SettingOptions.jsx';
 import GetHSKLevel from '../components/GetHSKLevel.jsx';
 import PlaySound from '../components/PlaySound.jsx';

 // import data
import character1 from '../data/character1-2021.json';
// import character2 from '../data/character2.json';
// import character3 from '../data/character3.json';
// import character4 from '../data/character4.json';
// import character5 from '../data/character5.json';
// import character6 from '../data/character6.json';


import word1 from '../data/word1-2021.json';
//  import word2 from '../data/word2.json';
//  import word3 from '../data/word3.json';
//  import word4 from '../data/word4.json';
//  import word5 from '../data/word5.json';
//  import word6 from '../data/word6.json';
  import waiting from '../data/test.json';
// Meta tags and Title
import {Helmet} from "react-helmet";


export default class HskGrid extends Component {
    constructor(props) {
        super(props);

        // try to get the slider state from url on loading page
        const urltest = window.location.pathname;

        // Build the correct title h1 dependin on 

        if (urltest.includes("voca")) {
            this.state = {
                // original hanzis object
                hanzis: [],
                // Filtered hanzis object after search
                hanzis_search: [],
                hanzi: [],
                hanzi_prev: [],
                hanzi_2_prev: [],
                hsklevel: 1,
                // set determine whether worlist or character is selected (true is character)
                set: false,
                // options for sorting the grid
                sort_options: "freq",
                // search input for grid
                search: '',
                isTrue: false,
                currenttitle: ' vocabulary list ordered by pinyin',
                wordHSK: 'New HSK ',
                //'HSK 1 characters list ordered by pinyin'

            };
        } else {
            this.state = {
                // original hanzis object
                hanzis: [],
                // Filtered hanzis object after search
                hanzis_search: [],
                hanzi: [],
                hanzi_prev: [],
                hanzi_2_prev: [],
                hsklevel: 1,
                // set determine whether worlist or character is selected (true is character)
                set: true,
                // options for sorting the grid
                sort_options: "freq",
                // search input for grid
                search: '',
                isTrue: false,
                currenttitle: 'vocabulary list ordered by pinyin',

            };
        }




        // Binding to make `this` work in the callback
        this.handleClickCharacter = this.handleClickCharacter.bind(this);
        this.handleClickHSK = this.handleClickHSK.bind(this);
        this.fetchHanzi = this.fetchHanzi.bind(this);
        this.handleSlider = this.handleSlider.bind(this);
        this.handleSort = this.handleSort.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.buildTitle = this.buildTitle.bind(this);

    }


    // fetch the hanzis hsk level 1 from API - default when landing on the page
    componentDidMount() {
        
        // 'a nice string'.indexOf('nice') !== -1  // true
        const urltest = window.location.pathname; //this.props.location.search;
        var isCharaclist = true;
        var hskcurrentlevel = 0;

        if (urltest.includes("voca")) {
            isCharaclist = false;
        } 

        hskcurrentlevel = GetHSKLevel(urltest);
       
        this.setState({
            hsklevel: hskcurrentlevel,
            set: isCharaclist,  
            currenttitle: hskcurrentlevel,          //wordslist vs characterslist false == wordslist
        });
        
        this.buildTitle(isCharaclist,hskcurrentlevel);


        if (isCharaclist === false) {
            this.setState({
                hanzis: word1,
                hanzis_search: word1      //wordslist vs characterslist false == wordslist
            });
            
                
        } else {
            this.setState({
                hanzis: character1,
                hanzis_search: character1,      //wordslist vs characterslist false == wordslist
            });
            
        }
    }

        buildTitle(isCharac,levelofHSK,sortOption) {
            
            var endOftitle = "";
            var currenttitlebuilder = "New HSK ";


            if (isCharac) {
                if (sortOption && sortOption === "rand") {
                    endOftitle =  ' characters list ordered randomly';
                } else if (sortOption && sortOption === "pinyin") {
                    endOftitle =  ' characters list ordered by pinyin';
                } else if (sortOption && sortOption === "strokes") {
                    endOftitle =  ' characters list ordered by strokes number';
                } else if (sortOption && sortOption === "genstand") {
                    endOftitle =  ' characters list ordered by general standard';
                } else {
                    endOftitle = ' characters list ordered by frequency';
                }
            } else {
                if (sortOption && sortOption === "rand") {
                    endOftitle = ' vocabulary list ordered randomly';
                } else {
                    endOftitle =  ' vocabulary list ordered by pinyin';
                }
            }
            currenttitlebuilder = "New HSK " + levelofHSK + endOftitle
            this.setState({
                currenttitle: currenttitlebuilder,          //wordslist vs characterslist false == wordslist
            });
        }



    // Fetch data function
    // argument level to call for selected hsklevel
		fetchHanzi(id) {
            if (id === 1) {
                id = "1"
            }
            if (this.state.set === true) {
                if (id === '1') {
                    this.setState({
                        hanzis: character1,
                        hanzis_search: character1
                    });
                } else  {
                        this.setState({
                            hanzis: waiting,
                            hanzis_search: waiting

                 /*   
                } else if (id === '2') {
                    this.setState({
                        hanzis: character2,
                        hanzis_search: character2
                    });
                } else if (id === '3') {
                    this.setState({
                        hanzis: character3,
                        hanzis_search: character3
                    });
                }  else if (id === '4') {
                    this.setState({
                        hanzis: character4,
                        hanzis_search: character4
                    });
                }  else if (id === '5') {
                    this.setState({
                        hanzis: character5,
                        hanzis_search: character5
                    });
                }  else if (id === '6') {
                    this.setState({
                        hanzis: character6,
                        hanzis_search: character6   */
                    });
                }
            } else if (this.state.set === false) {
                if (id === '1') {
                    this.setState({
                        hanzis: word1,
                        hanzis_search: word1
                    });
                } else  {
                    this.setState({
                        hanzis: waiting,
                        hanzis_search: waiting

                /*    } else if (id === '2') {
                    this.setState({
                        hanzis: word2,
                        hanzis_search: word2
                    });
                } else if (id === '3') {
                    this.setState({
                        hanzis: word3,
                        hanzis_search: word3
                    });
                }  else if (id === '4') {
                    this.setState({
                        hanzis: word4,
                        hanzis_search: word4
                    });
                }  else if (id === '5') {
                    this.setState({
                        hanzis: word5,
                        hanzis_search: word5
                    });
                }  else if (id === '6') {
                    this.setState({
                        hanzis: word6,
                        hanzis_search: word6  */
                    
                 });
		    }
        }
    }


    // Handle slider to change from wordlist and characters databases
    handleSlider(e) {
        const currentset = this.state.set;
        const stifefe = "tejieji";
        this.setState({
            set: !currentset,
            search: "",
            tesssst : stifefe,
        },
            function () {
                this.fetchHanzi(this.state.hsklevel)
            });
        // refresh title
        this.buildTitle(!currentset,this.state.hsklevel);
    };

    // Handle Sorting of the hanzis cards
    handleSort(e) {
        if (e && e.target.value) {
            // Resolve the sorting method selected
            this.setState({
                sort_options: e.target.value
            }, function () {
                if (this.state.sort_options === "freq") {
                    this.setState({
                        hanzis_search: this.state.hanzis_search.sort((a, b) => (a.frequencyrank > b.frequencyrank) ? 1 : -1)
                    })

                } else if (this.state.sort_options === "pinyin") {
                    this.setState({
                        hanzis_search: this.state.hanzis_search.sort((a, b) => a.pinyin.localeCompare(b.pinyin))
                    })

                } else if (this.state.sort_options === "strokes") {
                    this.setState({
                        hanzis_search: this.state.hanzis_search.sort((a, b) => (a.strokecount > b.strokecount) ? 1 : -1)
                    })

                } else if (this.state.sort_options === "genstand") {
                    this.setState({
                        hanzis_search: this.state.hanzis_search.sort((a, b) => (a.generalstandard > b.generalstandard) ? 1 : -1)
                    })

                } else if (this.state.sort_options === "rand") {
                    this.setState({
                        hanzis_search: this.state.hanzis_search.sort(() => 0.5 - Math.random())
                    })
                }
                this.buildTitle(this.state.set,this.state.hsklevel,this.state.sort_options)
            })

        } else {
            // reset to freq on change of HSK level selection
            this.setState({
                sort_options: "freq"
            }, function () {
                this.setState({
                    hanzis_search: this.state.hanzis_search.sort((a, b) => (a.frequencyrank > b.frequencyrank) ? 1 : -1)
                })
            })
        }
    }

    // Handle search box
    handleSearch(e) {
        this.setState({
            search: e.target.value.toLowerCase(),
            hanzis_search: this.state.hanzis
        },
            function () {
                this.setState({
                    hanzis_search: this.state.hanzis.filter(
                        hanzi => hanzi.pinyinflat.toLowerCase().includes(this.state.search) |
                            hanzi.definition.toLowerCase().includes(this.state.search) |
                            hanzi.character.includes(this.state.search)
                    )
                },
                    function () {
                        this.handleSort()
                    })
            }
        )
    };

    // Toggle HSK level
    handleClickHSK(e) {
        this.setState({
            hsklevel: e.target.id,
            sort_options: "freq",
            search: ""
        },
            function () {
                this.fetchHanzi(this.state.hsklevel);
          //      this.handleSort()
            });

        // Support rendering of hsk level selection
        // Manipulation of the hsklevel-default class

        // refresh title
        this.buildTitle(this.state.set,e.target.id);
    };


    // Toggle Hanzi to get translation and other info in the side card
    handleClickCharacter(e) {
        const id = e.target.id
        const hanziCurrent = this.state.hanzi;

        const card1 = document.getElementById("card-1")
        const card2 = document.getElementById("card-2")
        const card3 = document.getElementById("card-3")

        this.setState({
            hanzi: this.state.hanzis_search[id],
            hanzi_prev: hanziCurrent,
            hanzi_2_prev: this.state.hanzi_prev
        }, function () {
            if (Object.entries(this.state.hanzi).length !== 0) {
                card1.classList.remove("hide-card")
            }

            if (Object.entries(this.state.hanzi_prev).length !== 0) {
                card2.classList.remove("hide-card")
            }

            if (Object.entries(this.state.hanzi_2_prev).length !== 0) {
                card3.classList.remove("hide-card")
            }
        });
    }
// <Link to={this.state.set ? ('/characters-list/HSK'+this.state.hsklevel) : ('/words-list/HSK'+this.state.hsklevel)}>

    render() {
        return (
            <div>
                <Helmet>
                    <title>{this.state.currenttitle}</title>
                    <meta name="description" content="New HSK Vocabulary lists HSK 1 words and characters lists are searchable, sortable, and smoothly displayed on all devices. 新的 HSK1 词汇表, HSK1 nueva lista de palabras, HSK1 danh sách từ mới, daftar kata baru senarai perkataan baru, รายการคำศัพท์ใหม่." />
                    <link rel="canonical" href="https://hskabc.com/New-HSK1-vocabulary" />
                </Helmet>
                <NavHeader />
                <div className="page-content">
                <div className="sticky-top level-select h-100">
                    <div className="row justify-content-center align-items-center text-center">
                    <Router>
                        <div className="form-group">
                        
                        <Link to={this.state.set ? '/New-HSK1-characters' : '/New-HSK1-vocabulary'}>
                            <button id="1" className={this.state.hsklevel === 1 ? "btn btn-outline-light btn-hsk-mobile" : "btn btn-secondary btn-hsk-mobile"}
                             onClick={this.handleClickHSK} type="button" aria-pressed="true">HSK 1</button></Link>
                            <Link to={this.state.set ? '/HSK-2/New-HSK-2-characters' : '/HSK-2/New-HSK-2-vocabulary'}>
                                <button id="2" className={this.state.hsklevel === 2 ? "btn btn-outline-light btn-hsk-mobile" : "btn btn-secondary btn-hsk-mobile"}
                             onClick={this.handleClickHSK} type="button" aria-pressed="true">HSK 2</button></Link>
                            <Link to={this.state.set ? '/HSK-3/New-HSK-3-characters' : '/HSK-3/New-HSK-3-vocabulary'}>
                                <button id="3" className={this.state.hsklevel === 3 ? "btn btn-outline-light btn-hsk-mobile" : "btn btn-secondary btn-hsk-mobile"} 
                            onClick={this.handleClickHSK} type="button" aria-pressed="true">HSK 3</button></Link>
                            <Link to={this.state.set ? '/HSK-4/New-HSK-4-characters' : '/HSK-4/New-HSK-4-vocabulary'}>
                            <button id="4" className={this.state.hsklevel === 4 ? "btn btn-outline-light btn-hsk-mobile" : "btn btn-secondary btn-hsk-mobile"}
                             onClick={this.handleClickHSK} type="button" aria-pressed="true">HSK 4</button></Link>
                            <Link to={this.state.set ? '/HSK-5/New-HSK-5-characters' : '/HSK-5/New-HSK-5-vocabulary'}>
                            <button id="5" className={this.state.hsklevel === 5 ? "btn btn-outline-light btn-hsk-mobile" : "btn btn-secondary btn-hsk-mobile"} 
                            onClick={this.handleClickHSK} type="button" aria-pressed="true">HSK 5</button></Link>
                            <Link to={this.state.set ? '/HSK-6/New-HSK-6-characters' : '/HSK-6/New-HSK-6-vocabulary'}>
                            <button id="6" className={this.state.hsklevel === 6 ? "btn btn-outline-light btn-hsk-mobile" : "btn btn-secondary btn-hsk-mobile"} 
                            onClick={this.handleClickHSK} type="button" aria-pressed="true">HSK 6</button></Link>

                                <label className="switch">
                               
                                    <input type="checkbox" defaultChecked={this.state.set} id="hanzi-set" onClick={this.handleSlider} />
                                    
                                    <div className="slider round">
                                        <span className="on">Characters</span>
                                        <span className="off">Words</span>
                                    </div>

                                </label>
                                

                            <form className="form-inline search-box">
                                <input
                                    className="form-control search-text"
                                    placeholder="Search..."
                                    value={this.state.search}
                                    onChange={this.handleSearch}
                                />
                            </form>

                            <div className="dropdown options">
                                <i className=" fa fa-cog"
                                    id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></i>

                                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <SettingOptions
                                        sort_options={this.state.sort_options}
                                        set={this.state.set}
                                        onChange={this.handleSort} />
                                </div>
                            </div>

                        </div>
                        </Router>
                    </div>
                </div>

                {this.state.hanzi.character && <div className="row sticky-top align-items-center mobile-card">
                    <div className="col-sm-auto lead text-center">
                        <h4 className="mobile-select">{this.state.hanzi.character}   {this.state.hanzi.pinyin} 
                        < PlaySound characters = {this.state.hanzi.character}/></h4>
                    </div>
                    <div className="col-sm-auto text-center">
                        <b>{this.state.hanzi.definition}</b>
                    </div>
        </div> }
            <h1>{this.state.currenttitle}</h1>
                <div className="container-fluid character">
                    <div className="row">
                        <div className="col-3 col-sm-3 sticky-top card-display">
                            <CardCarousel hanzi={this.state.hanzi}
                                hanzi_prev={this.state.hanzi_prev}
                                hanzi_2_prev={this.state.hanzi_2_prev} />
                        </div>

                        <div className="col-9 col-sm-9 mobile-col">
                            <div>
                                <GridMaster res={this.state.hanzis_search}
                                    set={this.state.set}
                                    onClick={this.handleClickCharacter} />
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                <Footer />
                
            </div >
        )

    }
}