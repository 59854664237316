import React from 'react'

import PlaySound from '../components/PlaySound.jsx'

// import custom style
import './CardCarousel.css';

const CardCarousel = ({ hanzi, hanzi_prev, hanzi_2_prev }) => {

    return (
        <div id="card_container" className="sticky-top card_container">
            <ul>
                <div id="card-3" className="deck hide-card">
                    <div className="card card-3 text-center">
                        <div className="character-3"></div>
                        <h2 className="card-header" >{hanzi_2_prev.character} <PlaySound characters = {hanzi_2_prev.character} /></h2> 

                        <div className="card-body">
                            <h5 className="card-title">{hanzi_2_prev.pinyin}</h5>
                            <h5 className="card-text">{hanzi_2_prev.definition}</h5>
                        </div>
                    </div>
                </div>

                <div id="card-2" className="deck hide-card">
                    <div className="card card-2 text-center">
                        <div className="character-2"></div>
                        <h2 className="card-header" >{hanzi_prev.character} <PlaySound characters = {hanzi_prev.character} /></h2>

                        <div className="card-body">
                            <h5 className="card-title">{hanzi_prev.pinyin}</h5>
                            <h5 className="card-text">{hanzi_prev.definition}</h5>

                        </div>
                    </div>
                </div>

                <div id="card-1" className="deck hide-card">
                    <div className="card card-1 text-center">
                        <div className="character-1"></div>
                        <h2 className="card-header" >{hanzi.character} <PlaySound characters = {hanzi.character} /></h2>

                        <div className="card-body">
                            <h5 className="card-title">{hanzi.pinyin}</h5>
                            <h5 className="card-text">{hanzi.definition}</h5>

                        </div>
                    </div>
                </div>

            </ul>
        </div>
    )
};


export default CardCarousel

