import React, { Component } from 'react';
import axios from 'axios'

// Import component
import NavHeader from '../components/NavHeader.jsx';
import Footer from '../components/Footer.jsx';
import GridGames from '../components/GridGames.jsx';
import GetHSKLevel from '../components/GetHSKLevel.jsx';

// Meta tags and Title
import {Helmet} from "react-helmet";

// Import custom style
import './Games.css'
import { Link } from 'react-router-dom';

export default class GamePinyin extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // original hanzis object
            hanzis: [],
            // Games list
            hanzisGame: [],
            hanziCurrent: ["charac",
                "display",
                false],
            selectedhanzi: [],
            hanzi: "no",
            hsklevel: 1,
            nbrFound: 0,
            nbrTotal: 0,
            shouldHide: false,
            // set last game tile
            lasthanzi: '',
            title: "",
            
        };

        // Binding to make `this` work in the callback
        this.handleClickCharacter = this.handleClickCharacter.bind(this);
        this.handleWinner = this.handleWinner.bind(this);
        this.fetchList = this.fetchList.bind(this);
        this.refreshPage = this.refreshPage.bind(this);
    }


    // 
    componentDidMount() {
        const urltest = window.location.pathname; //this.props.location.search;
        var hskcurrentlevel = 0;
        var currentTitle = "";
        hskcurrentlevel = GetHSKLevel(urltest);
        currentTitle = "Pinyin to words HSK " + hskcurrentlevel + " game"
        this.setState({
            hsklevel: hskcurrentlevel,
            title: currentTitle,          //wordslist vs characterslist false == wordslist
        });



        // by default on landing on the page without redirection get the HSK1 list
        if (isNaN(hskcurrentlevel)) {
            this.fetchList(1);
        } else {
            this.fetchList(hskcurrentlevel);
        }

    }

    // Fetch data function
    // argument level to call for selected hsklevel
    fetchList(id) {
        axios.defaults.headers.common = {};
        axios.defaults.headers.common.accept = 'application/json';

        // Get a randomized list to a certain number
        axios.get('https://api.hskabc.com/games/wordlist/' + id +'/12')
            .then(res => {
                this.setState({
                    hanzis: res.data,
                })
                
                // Fill the game list of pinyin and characters
                this.state.hanzis.forEach(han => {
                    this.setState({
                        hanzisGame: this.state.hanzisGame.concat({
                            "character": han.character,
                            "display": han.character,
                            "found": false,
                            "isPinyin": false,
                        },
                            {
                                "character": han.character,
                                "display": han.pinyin,
                                "found": false,
                                "isPinyin": true,
                            })
                    })
                });

                // randomization of pinyin and characters
                this.setState({
                    hanzisGame: this.state.hanzisGame.sort(() => 0.5 - Math.random()),
                    nbrTotal: this.state.hanzisGame.length,
                })
                

                    ;
            });
    }

    refreshPage() {
        window.location.reload();
    }


    handleWinner(id) {
        const hanziCurrent = this.state.hanzisGame;
        const foundCharac = hanziCurrent[id].character;
        hanziCurrent[id].found = true;
        var nbrFoundconst = this.state.nbrFound;

        // Give found = true for the 2 corresponding hanzi
        hanziCurrent.forEach((han) => {
            if (han.character === foundCharac) {
                han.found = true;
                nbrFoundconst = this.state.nbrFound + 2;
            }
        })

        this.setState({
            hanzisGame: hanziCurrent,
            hanzi: this.state.hanzisGame[id].found.toString(),
            nbrFound : nbrFoundconst,
        })

        if (nbrFoundconst === this.state.nbrTotal) {

            this.setState({
                shouldHide: true
            });
        }

    }


    // Toggle Hanzi to get translation and other info in the side card
    handleClickCharacter(e) {

        const id = e.target.id
        this.setState({
            lasthanzi: this.state.selectedhanzi,
            selectedhanzi: this.state.hanzisGame[id],
        }, function () {
            if (this.state.selectedhanzi.character === this.state.lasthanzi.character) {
                this.handleWinner(id);
            }
        });

    };

    render() {
        return (
            <div>
                <Helmet>
                    <title>{this.state.title}</title>
                    <meta name="description" content="Find the pinyin corresponding to the word and vice versa." />
                </Helmet>
                <NavHeader />
                <div className="page-content">
                    <h1>{this.state.title}</h1>
                    <div className="container my-5">

                        <div className="row">

                            <div>
                                <GridGames res={this.state.hanzisGame}
                                    onClick={this.handleClickCharacter} />
                            </div>
                        </div>
                        <div>
                            {this.state.shouldHide &&
                                <div className="result-end">

                                    <h4 className="text-muted">Congratulations !!!</h4>
                                    <button type="button" onClick={this.refreshPage} className="btn btn-success">Play again</button>
                                    <Link to="/chinese-learning-games">
                                        <button type="button" className="btn btn-info">Back to Games</button>
                                    </Link>
                                </div>

                            }
                        </div>
                    </div>
                </div>
                <Footer />

            </div>
        )
    }
}
