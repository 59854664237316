import React, { Component } from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

// Meta tags and Title
import {Helmet} from "react-helmet";

// Google Analytics
import ReactGA from 'react-ga';

// Import component
import ScrollToTop from '../src/components/ScrollToTop.jsx';

// Import pages components
import Home from './pages/Home.jsx';
import HskGrid from './pages/HskGrid.jsx';
import HskGridNew from './pages/HskGridNew.jsx';
import HskDates from './pages/HskDates.jsx';
import Faq from './pages/Faq.jsx';
import Aboutus from './pages/Aboutus.jsx';
import Contactus from './pages/Contactus.jsx';
import Games from './pages/Games.jsx';
import GameMemory from './pages/GameMemory.jsx';
import GamePinyin from './pages/GamePinyin.jsx';
import NotFound from './pages/NotFound.jsx';

// const history = createBrowserHistory();

// Google Analytics tracking initialize
const trackingId = "UA-142466199-1"; 
ReactGA.initialize(trackingId);

//  Tracking pageview with GA
ReactGA.pageview(window.location.pathname + window.location.search);
ReactGA.pageview('/');
ReactGA.pageview('/hskgrid');
ReactGA.pageview('/HSK-test-dates');
ReactGA.pageview('/faq');
ReactGA.pageview('/chinese-learning-games');
ReactGA.pageview('/About-us');
ReactGA.pageview('/Contact-us');
ReactGA.pageview('/HSK-1/HSK-1-vocabulary');
ReactGA.pageview('/HSK-2/HSK-2-vocabulary');
ReactGA.pageview('/HSK-3/HSK-3-vocabulary');
ReactGA.pageview('/HSK-4/HSK-4-vocabulary');
ReactGA.pageview('/HSK-5/HSK-5-vocabulary');
ReactGA.pageview('/HSK-6/HSK-6-vocabulary');
ReactGA.pageview('/HSK-1/HSK-1-characters');
ReactGA.pageview('/HSK-2/HSK-2-characters');
ReactGA.pageview('/HSK-3/HSK-3-characters');
ReactGA.pageview('/HSK-4/HSK-4-characters');
ReactGA.pageview('/HSK-5/HSK-5-characters');
ReactGA.pageview('/HSK-6/HSK-6-characters');
ReactGA.pageview('/New-HSK1-vocabulary');
ReactGA.pageview('/New-HSK2-vocabulary');
ReactGA.pageview('/New-HSK3-vocabulary');
ReactGA.pageview('/New-HSK4-vocabulary');
ReactGA.pageview('/New-HSK5-vocabulary');
ReactGA.pageview('/New-HSK6-vocabulary');
ReactGA.pageview('/New-HSK7-8-9-vocabulary');
ReactGA.pageview('/New-HSK1-characters');
ReactGA.pageview('/New-HSK2-characters');
ReactGA.pageview('/New-HSK3-characters');
ReactGA.pageview('/New-HSK4-characters');
ReactGA.pageview('/New-HSK5-characters');
ReactGA.pageview('/New-HSK-6-characters');
ReactGA.pageview('/New-HSK-7-8-9-characters');
ReactGA.pageview('/chinese-learning-games/pinyin-game-HSK-1');
ReactGA.pageview('/chinese-learning-games/pinyin-game-HSK-2');
ReactGA.pageview('/chinese-learning-games/pinyin-game-HSK-3');
ReactGA.pageview('/chinese-learning-games/pinyin-game-HSK-4');
ReactGA.pageview('/chinese-learning-games/pinyin-game-HSK-5');
ReactGA.pageview('/chinese-learning-games/pinyin-game-HSK-6');
ReactGA.pageview('/chinese-learning-games/memory-game-HSK-1');
ReactGA.pageview('/chinese-learning-games/memory-game-HSK-2');
ReactGA.pageview('/chinese-learning-games/memory-game-HSK-3');
ReactGA.pageview('/chinese-learning-games/memory-game-HSK-4');
ReactGA.pageview('/chinese-learning-games/memory-game-HSK-5');
ReactGA.pageview('/chinese-learning-games/memory-game-HSK-6');

class App extends Component {
  render() {
    return (
      <div className="application">
            <Helmet>
                <meta charSet="utf-8" />
                <title>Discover the best HSK online learning experience</title>
                <link rel="canonical" href="https://hskabc.com/New-HSK1-vocabulary"  />
            </Helmet>
        
      <Router>

          <ScrollToTop>
          <div>
          <Switch>
            <Route exact path="/" component={Home}/>
            <Route exact path="/hskgrid" component={HskGrid}/>
            <Route exact path="/HSK-1/HSK-1-vocabulary" component={HskGrid}/>
            <Route exact path="/HSK-2/HSK-2-vocabulary" component={HskGrid}/>
            <Route exact path="/HSK-3/HSK-3-vocabulary" component={HskGrid}/>
            <Route exact path="/HSK-4/HSK-4-vocabulary" component={HskGrid}/>
            <Route exact path="/HSK-5/HSK-5-vocabulary" component={HskGrid}/>
            <Route exact path="/HSK-6/HSK-6-vocabulary" component={HskGrid}/>
            <Route exact path="/HSK-1/HSK-1-characters" component={HskGrid}/>
            <Route exact path="/HSK-2/HSK-2-characters" component={HskGrid}/>
            <Route exact path="/HSK-3/HSK-3-characters" component={HskGrid}/>
            <Route exact path="/HSK-4/HSK-4-characters" component={HskGrid}/>
            <Route exact path="/HSK-5/HSK-5-characters" component={HskGrid}/>
            <Route exact path="/HSK-6/HSK-6-characters" component={HskGrid}/>
            <Route exact path="/hskgridNew" component={HskGrid}/>
            <Route exact path="/New-HSK1-vocabulary" component={HskGridNew}/>
            <Route exact path="/New-HSK2-vocabulary" component={HskGridNew}/>
            <Route exact path="/New-HSK3-vocabulary" component={HskGridNew}/>
            <Route exact path="/New-HSK4-vocabulary" component={HskGridNew}/>
            <Route exact path="/New-HSK5-vocabulary" component={HskGridNew}/>
            <Route exact path="/New-HSK6-vocabulary" component={HskGridNew}/>
            <Route exact path="/New-HSK7-8-9-vocabulary" component={HskGridNew}/>
            <Route exact path="/New-HSK1-characters" component={HskGridNew}/>
            <Route exact path="/New-HSK2-characters" component={HskGridNew}/>
            <Route exact path="/New-HSK3-characters" component={HskGridNew}/>
            <Route exact path="/New-HSK4-characters" component={HskGridNew}/>
            <Route exact path="/New-HSK5-characters" component={HskGridNew}/>
            <Route exact path="/New-HSK6-characters" component={HskGridNew}/>
            <Route exact path="New-HSK7-8-9-characters" component={HskGridNew}/>
            <Route exact path="/HSK-test-dates" component={HskDates}/>
            <Route exact path="/faq" component={Faq}/>
            <Route exact path="/About-us" component={Aboutus}/>
            <Route exact path="/Contact-us" component={Contactus}/>
            <Route exact path="/chinese-learning-games" component={Games}/>
            <Route exact path="/chinese-learning-games/pinyin-game-HSK-1" component={GamePinyin}/>
            <Route exact path="/chinese-learning-games/pinyin-game-HSK-2" component={GamePinyin}/>
            <Route exact path="/chinese-learning-games/pinyin-game-HSK-3" component={GamePinyin}/>
            <Route exact path="/chinese-learning-games/pinyin-game-HSK-4" component={GamePinyin}/>
            <Route exact path="/chinese-learning-games/pinyin-game-HSK-5" component={GamePinyin}/>
            <Route exact path="/chinese-learning-games/pinyin-game-HSK-6" component={GamePinyin}/>
            <Route exact path="/chinese-learning-games/memory-game-HSK-1" component={GameMemory}/>
            <Route exact path="/chinese-learning-games/memory-game-HSK-2" component={GameMemory}/>
            <Route exact path="/chinese-learning-games/memory-game-HSK-3" component={GameMemory}/>
            <Route exact path="/chinese-learning-games/memory-game-HSK-4" component={GameMemory}/>
            <Route exact path="/chinese-learning-games/memory-game-HSK-5" component={GameMemory}/>
            <Route exact path="/chinese-learning-games/memory-game-HSK-6" component={GameMemory}/>
            <Route component={NotFound} />
            </Switch>
          </div>
        </ScrollToTop>
       
      </Router>
      </div>
    );
  }
}

export default App;