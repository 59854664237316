import React, { Component } from 'react';


// Import component
import NavHeader from '../components/NavHeader.jsx';
import Footer from '../components/Footer.jsx';

// Import custom style
import './Aboutus.css' 

// Meta tags and Title
import {Helmet} from "react-helmet";

export default class ContactUs extends Component {
    render() {
        return (
            <div>
                <Helmet>
                    <title>Contact us</title>
                    <meta name="description" content="Contact us, share your feedbacks on our website." />
                </Helmet>
                <NavHeader />
                <div className="page-content">
                <h1>Contact Us</h1>
                <div className="col-sm about">
                </div>


            </div>
            <Footer />

        </div>
    )
}
}
