import React from 'react';


// Import component
import NavHeader from '../components/NavHeader.jsx';
import Footer from '../components/Footer.jsx';

// Import custom style
import './NotFound.css'


import languagepng from '../media/img/language.png';

// import PageNotFound from '../assets/images/PageNotFound';
class NotFoundPage extends React.Component{
    render(){
        return <div>
            <NavHeader />
            <h1>Page not found, 你在哪儿？</h1>
            <div className="col-sm align-items-center mb-3"></div>
            <a href="/words-HSK-1">
                                <img className="img-fluid language center" align="center" alt="" src={languagepng} />
                            </a>
            <div/>

            <Footer />

        </div>

        ;
    }
}
export default NotFoundPage;