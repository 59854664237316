import React from 'react'

// Custom style
import './GridGames.css'

const GridGameMemory = ({ res, set, onClick }) => {
    // Create grid card element to display
    // Create card by mapping over the hanzis fetched in home

    const hanzi = Object.keys(res).map((h, i) => {
                  
            return (
                <button key={i} id={i} onClick={onClick} className={`col-1 btn hanzi `}>
                    {res[h].display}
                </button>
            )
        });
    
        return (
            <div>
                {hanzi}
            </div>
    
        )
};


export default GridGameMemory

