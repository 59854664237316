import React, { Component } from 'react';
import { Link } from 'react-router-dom'

// Import custom style
import './NavHeader.css'

// Import logo
import logo from '../media/img/hsk-logo-l3-2.png'



export default class NavHeader extends Component {
    state = { isHide: true };

    hideBar = () => {
        const { isHide } = this.state

        window.scrollY > -1 ?
            !isHide && this.setState({ isHide: true })
            :
            isHide && this.setState({ isHide: false });

    }

    componentDidMount() {
        window.addEventListener('scroll', this.hideBar);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.hideBar);
    }


    render() {
        //   const classHide = this.state.isHide ? '' : 'show';

        return (
            <nav className="navbar navbar-expand-lg navbar-dark bg-dark box-shadow sticky-top">

                <a href="/" className=" navbar-brand mr-auto p-2">
                    <img src={logo} alt="HSK ABC Logo" height="65px" />
                </a>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarHeader" aria-controls="navbarHeader" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>


                <div className="collapse navbar-collapse" id="navbarHeader">
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item">
                        <Link to='/New-HSK1-vocabulary'>
                                <button className="btn btn-dark">New HSK 3.0 Lists</button>
                            </Link>
                            <Link to='/HSK-1/HSK-1-vocabulary'>
                                <button className="btn btn-dark">HSK 2.0 Lists</button>
                            </Link>
                            <Link to='/HSK-test-dates'>
                                <button className="btn btn-dark">HSK Dates</button>
                            </Link>
                            <Link to='/chinese-learning-games'>
                                <button className="btn btn-dark">Games</button>
                            </Link>
                            <Link to='/faq'>
                                <button className="btn btn-dark">FAQ</button>
                            </Link>
                        </li>
                    </ul>

                </div>
            </nav>
        )
    }
}
